

































































import { Component, Vue } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/MerchantController'
import Button from '@/app/ui/components/Button/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { Utils } from '@/app/infrastructures/misc'

@Component({
  components: {
    Button,
    LoadingOverlay,
  },
})
export default class MerchantDetailLayout extends Vue {
  isGuest = Utils.isGuest()
  controller = controller
  modal: string | null = null
  routes = [
    {
      name: 'Profile',
      route: 'MerchantDetailProfilePage',
    },
    {
      name: 'Account',
      route: 'MerchantDetailAccountPage',
    },
    {
      name: 'Product',
      route: 'MerchantProductListPage',
    },
  ]

  created(): void {
    controller.getMerchantDetail(this.$route.params.id)
  }

  private showModal(modalName: string): void {
    this.modal = modalName
  }

  get isRegistered(): boolean {
    return controller.merchantDetail?.status !== 'registered'
  }

  get merchantStatus(): boolean {
    return controller.merchantDetail.status === 'active'
  }
}
